

























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import { Select, Option, Checkbox, CheckboxGroup } from 'element-ui'
Vue.use(Select)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)

@Component
export default class extends Vue {
  info = {
    // 用户名
    username: '',
    password: '',
    // 姓名
    nickname: '',
    roleIds: []
  }
  loading = false
  isAdd = false
  isResetPwd = false
  isHover = false
  checkAll = false
  rolesData: any = []
  infoRules = {
    username: [
      { required: true, message: '请输入用户名', trigger: 'blur' },
      { max: 20, message: '长度在20个字符以内', trigger: 'blur' },
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '请输入英文或数字',
        trigger: 'blur'
      }
    ],
    password: [
      { required: !this.isResetPwd, message: '请输入密码', trigger: 'blur' },
      { min: 6, message: '至少6位', trigger: 'blur' },
      { max: 20, message: '长度在20个字符以内', trigger: 'blur' },
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '请输入英文或数字',
        trigger: 'blur'
      }
    ],
    nickname: [
      { required: true, message: '请输入姓名', trigger: 'blur' },
      { max: 20, message: '长度在20个字符以内', trigger: 'blur' },
      {
        pattern: /^[0-9a-zA-Z\u4e00-\u9fa5]+$/,
        message: '请输入中文、英文或数字',
        trigger: 'blur'
      }
    ]
  }
  async beforeCreate() {
    // 获取角色列表
    await this.$api.people.getRoleLists({ size: '-1' }).then((res: any) => {
      const data = res.data
      if (data.success) {
        this.rolesData = data.data
      }
    })
  }

  created() {
    if (this.$route.query.id) {
      this.getRoleDetail()
    } else {
      this.isAdd = true
      this.isResetPwd = true
    }
  }

  getRoleDetail() {
    this.loading = true
    this.$api.people
      .getUserDetail(this.$route.query.id)
      .then((res: any) => {
        this.loading = false
        const data = res.data
        if (data.success) {
          this.info = {
            username: data.data.username || '',
            password: data.data.password || '',
            // 姓名
            nickname: data.data.nickname || '',
            roleIds: data.data.roleIds || []
          }
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  saveUser() {
    ;(this.$refs['info'] as any).validate((valid: any) => {
      if (valid) {
        this.loading = true
        if (this.$route.query.id) {
          this.$api.people
            .editUser({
              nickname: this.info.nickname,
              roleIds: this.info.roleIds,
              username: this.info.username,
              password: this.isResetPwd ? this.info.password : '',
              id: this.$route.query.id
            })
            .then((res: any) => {
              this.loading = false
              if (res.data.success) {
                this.$message.success(res.data.msg)
                this.goback()
              }
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          this.$api.people
            .addUser(this.info)
            .then((res: any) => {
              this.loading = false
              if (res.data.success) {
                this.$message.success(res.data.msg)
                this.goback()
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      }
    })
  }

  handleCheckAllChange(val: boolean) {
    this.info.roleIds = val
      ? this.rolesData.map((v: any) => {
          return v.id
        })
      : []
  }

  handleCheckedRolesChange(value: any) {
    const checkedCount = value.length
    this.checkAll = checkedCount === this.rolesData.length
  }

  // 返回
  goback() {
    this.$router.push('/people/administrator-list')
  }
}
